import React, { Component } from 'react';
import logo from './shoeweather.png';
import './App.css';
import ItemsContainer from './components/ItemsContainer'
import CityContainer from './components/CityContainer'
//import WelcomeContainer from './components/WelcomeContainer'

class App extends Component {
  render() {
    return (
          <div align="center">
            <img src="images/shoeweather_text2.png" alt="shoe weather" width="200px" />

        <CityContainer />
        <ItemsContainer />
      </div>
    );
  }
}

export default App;
