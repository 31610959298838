import React, { Component } from 'react'
import axios from 'axios'
class CityContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      city: "City"
    }
  }
    componentDidMount() {
    axios.get('https://service.shoeweather.com/api/v1/city')
    .then(response => {
      console.log(response)
      this.setState({city: response.data})
    })
    .catch(error => console.log(error))
  }

    render() {
    return (
     <h2 align="center" class="city" style={{ marginBottom: '10px' }}>Hi {this.state.city}</h2>
 

    )
  }
}


export default CityContainer
