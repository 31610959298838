import React, { Component } from 'react';
import axios from 'axios';

class ItemsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: { name: 'Loading', image: 'shoe_loading.png', brand: 'ShoeWeather', link: 'http://shoeweather.com' },
      temp: 'Loading',
      weather: 'Loading',
    };
  }

  componentDidMount() {
    axios.get('https://service.shoeweather.com/api/v1/items')
      .then(response => {
        console.log(response);
        const shoe = response.data[0];
        const rand_shoe = shoe[Math.floor(Math.random() * response.data[0].length)];
        this.setState({ item: rand_shoe, temp: response.data[1], weather: response.data[2] });
      })
      .catch(error => console.log(error));
  }

  render() {
    return (
      <div className="container">
        <h2 className="title">~feels like~ {this.state.temp}&deg;</h2>
        <h2 className="subtitle">{this.state.weather}</h2>
        <div className="tile" key={this.state.item.id}>
          <p><img src={"." + this.state.item.image} className="imgWidth" alt="Shoe" /></p>
          <div className="shoeDetails">
            <h4><span className="accent">shoes we like</span><br /> {this.state.item.name}</h4>
            <p>Brand - {this.state.item.brand}</p>
            <p><a href={this.state.item.link} className="btn">Visit Store</a></p>
            <p><button onClick={() => this.componentDidMount()} className="btn">Next Shoe</button></p>
          </div>
        </div>
      </div>
    );
  }
}

export default ItemsContainer;
